import styled from 'styled-components';
export const Klarna = styled.div`
  text-align: center;
  font: normal normal normal 16px/22px Neuzeit Grotesk;
  letter-spacing: 0px;
  color: #001c72;
  background: #ffafc753 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 20px 10px;
  margin-top: 30px;
  position: relative;

  &:before {
    content: ' ';
    background: url('/images/klarna.png');
    width: 59px;
    height: 33px;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: -20px;
    left: calc(50% - 30px);
  }
`;

export const KlarnaPopup = styled.span`
  text-decoration: underline;
  font: normal normal normal 16px/22px Neuzeit Grotesk;
  letter-spacing: 0px;
  color: #bc2e3e;
  cursor: pointer;
`;

import { ProductPrice } from '../../../utils/productPrice';
import * as S from './KlarnaContainer.styles';
import { formatPrice } from '../../../utils/formatPrice';
import React, { useContext } from 'react';
import FunnelContext from '../../../context/FunnelContext';

export const KlarnaContainer = props => {
  const {
    type,
    showKlarna,
    subKlarna,
    otpKlarna,
    otpKlarnaInstallments,
    subKlarnaInstallments,
    priceSettings,
    setShowKlarnaPopup
  } = props;

  const { currentCategory, currentProduct, extraObjects } = useContext(
    FunnelContext
  );

  const KlarnaMessage = () => {
    const discounted_price = ProductPrice({
      type: 'discounted_price',
      currentCategory: currentCategory,
      currentProduct: currentProduct,
      filterByTag: extraObjects?.filterProductItemsByTag || false
    });

    let klarna_installment = 0.0;
    let installments = 0;
    if (subKlarna && type === `sub`) {
      installments = subKlarnaInstallments || 3;
      klarna_installment =
        parseFloat(discounted_price) / parseInt(installments);
    }
    if (otpKlarna && type === `otp`) {
      installments = otpKlarnaInstallments || 3;
      klarna_installment =
        parseFloat(discounted_price) / parseInt(installments);
    }

    return (
      <S.Klarna>
        Or make {installments} interest-free payments of{' '}
        {formatPrice(klarna_installment.toFixed(2), priceSettings)}.{' '}
        <S.KlarnaPopup onClick={() => setShowKlarnaPopup(true)}>
          Learn more
        </S.KlarnaPopup>
      </S.Klarna>
    );
  };

  if (
    showKlarna &&
    ((subKlarna && type === `sub`) || (otpKlarna && type === `otp`))
  ) {
    return <KlarnaMessage />;
  } else {
    return ``;
  }
};

export default KlarnaContainer;
